import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { IAutocompleteOption } from '../components/common/autocomplete.tsx';
import { IQuestionsExportSettingsFormData } from '../components/questions/questions-export-settings-form.tsx';

export const truncateText = (
  text: string | null | undefined,
  maxLength: number,
): string => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  else return text.slice(0, maxLength) + '...';
};

export const getDateForInput = (date: Date | undefined): string | undefined => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getDateString = (dateServer: Date | undefined | null): string => {
  if (!dateServer) return '';
  return new Date(dateServer).toLocaleDateString('en-US');
};

export const orderToLetter = (order: number) => {
  if (order < 0 || order > 25) throw 'Invalid order index';
  return String.fromCharCode(65 + order);
};

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const getAutocompleteTitle = (
  option: IAutocompleteOption | undefined,
) => {
  if (!option) return undefined;
  return typeof option === 'string' ? option : option.title;
};
export const getAutocompleteKey = (option: IAutocompleteOption | undefined) => {
  if (!option) return undefined;
  return typeof option === 'string' ? option : option.key;
};

export const createXlsxExportUrl = (
  type: 'quiz' | 'test',
  id: number,
  attempts: number[],
): string => {
  const searchParams = new URLSearchParams({
    id: id.toString(),
    attemptIds: attempts.join(','),
  });
  return `${import.meta.env.VITE_API_URL}/${type}/xlsx?${searchParams}`;
};

export const createPdfExportUrl = (
  type: 'quiz' | 'test',
  params: IQuestionsExportSettingsFormData & {
    id: string;
    moduleIds?: string;
  },
): string => {
  const searchParams = new URLSearchParams(
    Object.entries(params).map(([key, value]) => [key, value.toString()]),
  );
  return `${import.meta.env.VITE_API_URL}/${type}/pdf?${searchParams}`;
};

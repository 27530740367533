import {
  QuestionRelationSortInput,
  QuestionSortInput,
  QuestionSourceSortInput,
  Sort,
} from '../../apollo/__generated__/graphql.ts';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { COPY_QUESTION, QUESTION_FRAGMENT } from '../../apollo/questions.ts';
import { useFragment } from '../../apollo/__generated__';
import { toast } from 'react-toastify';
import Modal from '../common/modal.tsx';
import Button from '../common/button.tsx';
import { getNextSortType } from '../table/table-utils.ts';

type SortType = Sort | QuestionRelationSortInput | QuestionSourceSortInput;

function isSort(sort: any): sort is Sort {
  return Object.values(Sort).includes(sort);
}
function isQuestionRelationSort(sort: any): sort is QuestionRelationSortInput {
  return typeof sort === 'object' && ('id' in sort || 'name' in sort);
}
function isQuestionSourceSort(sort: any): sort is { originalId: Sort } {
  return typeof sort === 'object' && 'originalId' in sort;
}

export const useQuestionSort = () => {
  const [sort, setSort] = useState<QuestionSortInput>({
    originalId: Sort.Asc,
  });

  const getNextCurrentValue = useCallback((value?: SortType) => {
    if (isQuestionRelationSort(value) && getNextSortType(value.id)) {
      return { id: getNextSortType(value.id) } as QuestionRelationSortInput;
    }
    if (isQuestionRelationSort(value) && getNextSortType(value.name)) {
      return { name: getNextSortType(value.name) } as QuestionRelationSortInput;
    }
    if (isQuestionSourceSort(value) && getNextSortType(value.originalId)) {
      return {
        originalId: getNextSortType(value.originalId),
      } as QuestionSourceSortInput;
    }
    if (isSort(value) && getNextSortType(value)) {
      return getNextSortType(value);
    }
    return undefined;
  }, []);

  const setQuestionSort = (key: string) => {
    const currentSortValue = sort[key as keyof QuestionSortInput];
    let newSortValue: SortType | undefined;

    switch (true) {
      case !!currentSortValue:
        newSortValue = getNextCurrentValue(currentSortValue);
        break;
      case key === 'difficulty':
        newSortValue = { id: getNextSortType() };
        break;
      case ['domain', 'skill', 'subject', 'type1', 'type2', 'section'].includes(
        key,
      ):
        newSortValue = { name: getNextSortType() };
        break;
      case key === 'sourceQuestion':
        newSortValue = { originalId: getNextSortType() };
        break;
      default:
        newSortValue = getNextSortType();
    }

    setSort({
      [key]: newSortValue,
    });
  };

  return [sort, setQuestionSort] as const;
};

export const useCopyQuestion = (id: number, displayValue?: number) => {
  const navigate = useNavigate();
  const [copy, { data: copiedQuestion }] = useMutation(COPY_QUESTION);
  const [isCopyModal, setCopyModal] = useState(false);
  const closeCopyModal = () => setCopyModal(false);
  const openCopyModal = () => setCopyModal(true);

  const copiedQuestionFragment = useFragment(
    QUESTION_FRAGMENT,
    copiedQuestion?.question.copy,
  );
  useEffect(() => {
    const id = copiedQuestionFragment?.id;
    if (id) navigate(`/edit-question/${id}`);
  }, [copiedQuestionFragment, navigate]);

  const onCopy = (id: number) => {
    const copyPromise = copy({
      variables: {
        input: {
          id,
        },
      },
    });

    toast.promise(copyPromise, {
      pending: 'Creating copy...',
      success: 'Copy created',
    });
    copyPromise.then(() => closeCopyModal());
  };

  const CopyModal = (): ReactNode => (
    <Modal
      title={'Are you sure?'}
      onClose={closeCopyModal}
      isOpen={isCopyModal}
    >
      <div className={'mt-4'}>
        You are about to duplicate QID
        <span className={'font-semibold'}> {displayValue}</span> as a draft.
      </div>
      <div className={'mt-4 grid grid-cols-2 gap-2'}>
        <Button white onClick={closeCopyModal}>
          Cancel
        </Button>
        <Button onClick={() => onCopy(id)}>Continue</Button>
      </div>
    </Modal>
  );

  return {
    CopyModal,
    openCopyModal,
  };
};

export const usePreviewQuestion = () => {
  const [isPreview, setIsPreview] = useState(false);
  const openPreview = () => setIsPreview(true);
  const closePreview = () => setIsPreview(false);

  return { openPreview, isPreview, closePreview };
};

export const useExplanation = () => {
  const [isExplanation, setIsExplanation] = useState(false);

  return { isExplanation, setIsExplanation };
};

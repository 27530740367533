import { useMutation } from '@apollo/client';
import { CREATE_QUIZ_ATTEMPT } from '../../apollo/quiz-attempts.ts';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { GET_MY_QUIZ_ATTEMPTS } from '../../apollo/user-assignments.ts';
import {
  QuestionAnswerType,
  QuizAttemptResultsFragmentFragment,
  QuizResultsFragmentFragment,
} from '../../apollo/__generated__/graphql.ts';
import { orderToLetter } from '../../utils/common-utils.ts';

export const useCreateQuizAttempt = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [create] = useMutation(CREATE_QUIZ_ATTEMPT, {
    refetchQueries: [GET_MY_QUIZ_ATTEMPTS],
  });
  const navigate = useNavigate();

  return (id?: number) => {
    if (!id || isLoading) return;
    setIsLoading(true);
    create({
      variables: {
        input: { quizId: id },
      },
    }).then((res) => {
      navigate(`/quiz-attempt/${res.data?.quizAttempt.create.id}`);
      setIsLoading(false);
    });
  };
};

export const useAttemptReviewData = () => {
  const getReviewData = (
    quizQuestions: QuizResultsFragmentFragment['quizQuestions'],
    attempt?: QuizAttemptResultsFragmentFragment | null,
  ) => {
    return quizQuestions?.map((item) => {
      const order = item.order;
      const question = attempt?.questions.find(
        (question) => question.question?.id === item.question?.id,
      );
      const isChoiceType =
        question?.question?.answerType === QuestionAnswerType.MultipleChoice;
      const correctAnswer = isChoiceType
        ? orderToLetter(question.correctChoice!.order)
        : question?.correctChoice?.text || '';
      const userAnswer = isChoiceType
        ? question.choice
          ? orderToLetter(question.choice.order)
          : ''
        : question?.text || '';
      return {
        order,
        correctAnswer,
        userAnswer,
        isCorrect: !!question?.correct,
      };
    });
  };

  return { getReviewData };
};

import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../common/button.tsx';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_COURSE, UPDATE_COURSE } from '../../apollo/courses.ts';
import { CourseFragment } from '../../apollo/__generated__/graphql.ts';
import { getDateForInput } from '../../utils/common-utils.ts';

interface IForm {
  name: string;
  subject: string;
  description: string;
  archiveAt: string;
}

interface IProps {
  course?: CourseFragment;
  onClose?: () => void;
}

const CreateCourseForm = ({ course, onClose }: IProps) => {
  const navigate = useNavigate();
  const [createCourse] = useMutation(CREATE_COURSE);
  const [updateCourse] = useMutation(UPDATE_COURSE);

  const archiveAtDate = course?.archiveAt
    ? new Date(course?.archiveAt)
    : undefined;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      name: course?.name,
      subject: course?.subject ? course.subject : undefined,
      description: course?.description ? course.description : undefined,
      archiveAt: getDateForInput(archiveAtDate),
    },
  });

  const onCreate: SubmitHandler<IForm> = (data) => {
    const create = createCourse({
      variables: {
        input: {
          name: data.name,
          description: data.description,
          subject: data.subject,
          archiveAt: new Date(data.archiveAt),
        },
      },
    });

    toast.promise(create, {
      pending: 'Creating course...',
      success: 'Course created',
    });
    create.then((data) => {
      navigate(`/courses-browser/${data.data?.course.create.id}`);
    });
  };

  const onUpdate: SubmitHandler<IForm> = (data) => {
    if (!course?.id) return;
    const update = updateCourse({
      variables: {
        input: {
          archiveAt: new Date(data.archiveAt),
          description: data.description,
          id: course.id,
          name: data.name,
          subject: data.subject,
        },
      },
    });

    toast.promise(update, {
      pending: 'Updating course...',
      success: 'Course updated',
    });
    update.then(() => {
      if (onClose) onClose();
    });
  };

  return (
    <section>
      {!course && <h1>Create new course</h1>}
      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="test-name">
          Name
        </label>
        <input
          type="text"
          className={'base-input'}
          placeholder={'e.g. Math Course'}
          {...register('name', { required: 'This field is required' })}
        />
        <p className={'h-4 text-sm text-red'}>
          {errors.name ? errors.name.message : ''}
        </p>
      </div>

      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="test-name">
          Subject
        </label>
        <input
          type="text"
          className={'base-input'}
          placeholder={'e.g. Math'}
          {...register('subject')}
        />
      </div>

      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="test-name">
          Description
        </label>
        <input
          type="text"
          className={'base-input'}
          placeholder={'Enter description...'}
          {...register('description')}
        />
      </div>

      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="test-name">
          Archive at
        </label>
        <input
          type="date"
          className={'base-input'}
          {...register('archiveAt')}
        />
      </div>

      <Button
        onClick={course ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
        className={'mt-10 w-full'}
      >
        {course ? 'Save' : 'Create'}
      </Button>
    </section>
  );
};
export default CreateCourseForm;
